export default function TodoSection() {
  return (
    <section className="section  pt-20 xl:pt-24">
      <p class="text-center text-lg mb-10 aos-init aos-animate" data-aos="fade-up-sm">
      </p>
      <div className="container">
        <div className="grid lg:grid-cols-3 items-center gap-8">
          <div data-aos="fade-up-sm" className="aos-init aos-animate">
            <h2 className="mb-4 text-h4">Nous aidons près de 5000 étudiants à obtenir leur diplôme et certificat professionnel</h2>
            <p>
            Depuis notre création, nous avons accompagné des milliers d'étudiants dans leur parcours éducatif, leur offrant les outils nécessaires pour réussir et obtenir des diplômes et certificats reconnus dans le monde professionnel
            </p>
          </div>
          <div
            data-aos="fade-up-sm"
            data-aos-delay="100"
            className="aos-init aos-animate"
          >
            <div className="border border-border shadow-inset p-10 rounded-4xl flex flex-col items-center gap-4 text-center w-full h-full">
              <div className="w-[60px] h-[60px] bg-theme-light rounded-full grid place-items-center text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  className="injected-svg"
                  data-src="images/icons/windspeed.svg"
                >
                  <g clip-path="url(#a-7)">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M9.928 7.793a1.251 1.251 0 1 0 0-2.505h-6.61c-.346 0-.653.133-.88.36a1.175 1.175 0 0 0-.36.88c0 .692.56 1.252 1.254 1.252h6.61l-.014.013zM9.3 16.322l-2.985.067c-.346 0-.653.16-.88.386a1.198 1.198 0 0 0-.346.893c.014.693.587 1.24 1.28 1.226l2.985-.067a1.252 1.252 0 0 0 1.226-1.279 1.251 1.251 0 0 0-1.28-1.226zm9.222-9.861c.946 2.825 1.72 3.598 4.544 4.544l.014-.013c1.226.413 1.226 2.119 0 2.532-2.826.946-3.598 1.719-4.545 4.544a1.32 1.32 0 0 1-1.266.92 1.308 1.308 0 0 1-1.265-.92c-.8-2.399-1.48-3.318-3.412-4.118a5.602 5.602 0 0 0-2.185-.426c-2.612-.014-9.155-.014-9.155-.014a1.251 1.251 0 0 1-.88-2.145c.227-.227.533-.36.88-.36h9.101c.787 0 1.546-.213 2.199-.64 1.146-.746 2.838-2.092 3.438-3.904.413-1.226 2.119-1.226 2.532 0z"
                      clip-rule="evenodd"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="a-7">
                      <path fill="#fff" d="M0 0h24v24H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h3 className="text-h5">Participation active</h3>
              <p className="text-sm">
                L’institut s’est vu confier, la responsabilité d’assurer des formations de qualité 
                aux jeunes étudiants de la ville d’Agadir et les préparer à la fois pour réussir leurs examens et à la fois pour 
                assurer leurs avenirs.
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up-sm"
            data-aos-delay="200"
            className="aos-init aos-animate"
          >
            <div className="border border-border shadow-inset p-10 rounded-4xl flex flex-col items-center gap-4 text-center w-full h-full">
              <div className="w-[60px] h-[60px] bg-theme-light rounded-full grid place-items-center text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  className="injected-svg"
                  data-src="images/icons/windspeed.svg"
                >
                  <g clip-path="url(#a-8)">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M9.928 7.793a1.251 1.251 0 1 0 0-2.505h-6.61c-.346 0-.653.133-.88.36a1.175 1.175 0 0 0-.36.88c0 .692.56 1.252 1.254 1.252h6.61l-.014.013zM9.3 16.322l-2.985.067c-.346 0-.653.16-.88.386a1.198 1.198 0 0 0-.346.893c.014.693.587 1.24 1.28 1.226l2.985-.067a1.252 1.252 0 0 0 1.226-1.279 1.251 1.251 0 0 0-1.28-1.226zm9.222-9.861c.946 2.825 1.72 3.598 4.544 4.544l.014-.013c1.226.413 1.226 2.119 0 2.532-2.826.946-3.598 1.719-4.545 4.544a1.32 1.32 0 0 1-1.266.92 1.308 1.308 0 0 1-1.265-.92c-.8-2.399-1.48-3.318-3.412-4.118a5.602 5.602 0 0 0-2.185-.426c-2.612-.014-9.155-.014-9.155-.014a1.251 1.251 0 0 1-.88-2.145c.227-.227.533-.36.88-.36h9.101c.787 0 1.546-.213 2.199-.64 1.146-.746 2.838-2.092 3.438-3.904.413-1.226 2.119-1.226 2.532 0z"
                      clip-rule="evenodd"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="a-8">
                      <path fill="#fff" d="M0 0h24v24H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h3 className="text-h5">Responsabilité</h3>
              <p className="text-sm">
                Développer le sens de responsabilité chez les futurs professionnels et les managers vis-à-vis 
                de leurs entreprises et de la société en général est essentiel pour préparer des leaders conscients et engagés
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
