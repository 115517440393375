import imaLogo from "../../assets/images/ima.png"

export default function Footer() {
  return (
    <footer>
      <div class="container">
        <div class="pb-11 border-b border-white/10">
          <div class="flex flex-col xl:flex-row items-start lg:items-center justify-between gap-8">
            <div class="flex flex-col lg:flex-row gap-8 items-start lg:items-center">
            <div className="flex items-center gap-2">
              <img 
                className="h-[35px] w-[35px] rounded-full"
                src={imaLogo}
              />
              <p className=" text-xl text-white font-bold">
                Institute Ima
              </p>
            </div>
              <div class="w-[1px] bg-white h-5 hidden lg:block"></div>
              <p class="xl:max-w-[420px]">
                L'avenir se charge
              </p>
            </div>

            <ul class="flex flex-col sm:flex-row flex-wrap gap-8">
              <li>
                <a
                  href="https://www.google.com/maps/place/Av.+Abdellah+Guenoun,+Agadir+80000/data=!4m2!3m1!1s0xdb3c82a125ac3b3:0xe0bb5c475ab2fd77?sa=X&ved=1t:242&ictx=111"
                  class="flex items-center gap-x-1 hover:text-white transition-colors duration-300"
                >
                  <span>Address</span>
                  <span class="bg-white/5 hover:bg-white/10 p-1 text-xs rounded-sm">
                    Av. Abdellah Guenoun, Agadir 80000
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="pt-11 pb-20">
          <div class="flex flex-col lg:flex-row gap-8 justify-between">
            <div class="order-2 lg:order-1">
              <p class="mb-1.5">Copyright © 2024 CloudPeak.</p>
              <p>iphecinstitut@gmail.com - institutimastf@gmail.com</p>
            </div>
            <div class="order-1 lg:order-2">
              <ul class="flex flex-col sm:flex-row flex-wrap gap-8">
                <li>
                  <a
                    href="policy.html"
                    class="flex items-center gap-x-1 hover:text-white transition-colors duration-300"
                  >
                    <span>Phone</span>
                    <span class="bg-white/5 hover:bg-white/10 p-1 text-xs rounded-sm">
                      0626-400022
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="terms.html"
                    class="flex items-center gap-x-1 hover:text-white transition-colors duration-300"
                  >
                    <span>Fix</span>
                    <span class="bg-white/5 hover:bg-white/10 p-1 text-xs rounded-sm">
                      05282-39873
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="order-3 lg:order-3">
              <ul class="text-white flex items-center gap-5">
                <li>
                  <a
                    target="_blank"
                    aria-label="facebook"
                    href="https://web.facebook.com/InstituIMA/?_rdc=1&_rdr#"
                    class="inline-block hover:text-primary transition-colors duration-300 w-6 h-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="none"
                      class="injected-svg"
                      data-src="images/icons/facebook.svg"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M10 16.667a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333zm0 1.667a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M10 8.333c0-.46.373-.833.834-.833h.833a.833.833 0 0 0 0-1.667h-.833a2.5 2.5 0 0 0-2.5 2.5V10H7.5a.833.833 0 0 0 0 1.666h.834v5a.833.833 0 1 0 1.666 0v-5h1.667a.833.833 0 1 0 0-1.666H10V8.333z"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    aria-label="instagram"
                    href="https://www.instagram.com/institutima_agadir/"
                    class="inline-block hover:text-primary transition-colors duration-300 w-6 h-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="none"
                      class="injected-svg"
                      data-src="images/icons/instagram.svg"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M13.334 3.334H6.667a3.333 3.333 0 0 0-3.333 3.333v6.667a3.333 3.333 0 0 0 3.333 3.333h6.667a3.333 3.333 0 0 0 3.333-3.333V6.667a3.333 3.333 0 0 0-3.333-3.333zM6.667 1.667a5 5 0 0 0-5 5v6.667a5 5 0 0 0 5 5h6.667a5 5 0 0 0 5-5V6.667a5 5 0 0 0-5-5H6.667z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M14.166 6.667a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667z"
                      ></path>
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M14.166 10a4.167 4.167 0 1 1-8.333 0 4.167 4.167 0 0 1 8.333 0zM12.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    aria-label="twitter"
                    href="https://www.linkedin.com/in/institut-ima-39434b228/?originalSubdomain=ma"
                    class="inline-block hover:text-primary transition-colors duration-300 w-5 h-5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="none"
                      class="injected-svg"
                      data-src="images/icons/linkedin.svg"
                    >
                      <g clip-path="url(#a-11)">
                        <path
                          fill="currentColor"
                          d="M18.52 0H1.477C.66 0 0 .645 0 1.441v17.114C0 19.352.66 20 1.477 20H18.52c.816 0 1.48-.648 1.48-1.441V1.44C20 .645 19.336 0 18.52 0zM5.934 17.043h-2.97V7.496h2.97v9.547zM4.449 6.195a1.72 1.72 0 1 1-.006-3.439 1.72 1.72 0 0 1 .006 3.44zm12.594 10.848h-2.965v-4.64c0-1.106-.02-2.532-1.543-2.532-1.543 0-1.777 1.207-1.777 2.453v4.719H7.797V7.496h2.844v1.305h.039c.394-.75 1.363-1.543 2.804-1.543 3.004 0 3.559 1.976 3.559 4.547v5.238z"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a-11">
                          <path fill="#fff" d="M0 0h20v20H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
