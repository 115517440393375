import React, { useEffect } from "react";
import Header from "../Components/Header";
import { Box } from "@mui/system";
import Banner from "../Components/home/Banner";
import Aos from "aos";
import TodoSection from "../Components/home/TodoSection";
import AboutSection from "../Components/home/AboutSection";
import CommonQuestions from "../Components/home/CommonQuestions";
import Contact from "../Components/home/Contact";
import Footer from "../Components/home/Footer";

function Home() {
  useEffect(() => {
    Aos.init({
      duration: 1200, // Optional: customize AOS settings (example)
      easing: 'ease-in-out',
      once: true, // Optional: animation happens only once
   })
  }, [])
  return (
    <Box>
      <Header />
      <Banner />
      <TodoSection />
      <AboutSection />
      <CommonQuestions />
      <Contact />
      <Footer />
    </Box>
  );
}

export default Home;