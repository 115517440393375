import { Box,TextField,Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../Components/Header";
import { Form, Link } from "react-router-dom";
import { resiterUser } from "../services/auth.services";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form"
import { isAuth } from "../services/auth.services";
export default function Register() {
  const navigate = useNavigate();
  const { register, handleSubmit, watch,
    formState: { errors }
  } = useForm();
  const [isLoding, setIsLoding] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthRes = await isAuth();
      if (isAuthRes) {
        toast.success("Vous êtes déjà connecté !");
        navigate("/");
      }
    }
    checkAuth();
  },  [])

  const onSubmit = async (data) => {
    setIsLoding(false);
    resiterUser(data).then((response) => {
      toast.success("Inscription réussie, veuillez vous connecter", {
        style: {
          border: '1px solid #713200',
          padding: '16px',
          color: '#713200',
        },
        iconTheme: {
          primary: '#713200',
          secondary: '#FFFAEE',
        },
      });
      setIsLoding(true);
      navigate("/login");
    }).catch((error) => {
      if (error.response.status === 409) {
        setIsLoding(true);
        toast.error("Email déjà utilisé", {
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
          iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
          },
        });
      }else {
        setIsLoding(true);
        toast.error("Erreur lors de l'inscription", {
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
          iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
          },
        });
      }
    });
  }

  const styleErorr = {
    color: "#713200",
    border: "2px solid red",
    outline: "none",
  }
  const requestForm = {
    fullname: {
      required: "Nom d'utilisateur complet est requis",
      maxLength: {
        value: 35,
        message: "Nom d'utilisateur complet ne doit pas dépasser 35 caractères"
      }
      // maxLength: { value: 35, message: "Nom d'utilisateur complet ne doit pas dépasser 35 caractères" },
    },
    email: {
      required: "Email est requis",
      maxLength: { value: 50, message: "Email ne doit pas dépasser 35 caractères" },
      pattern: { value: /^\S+@\S+$/i, message: "Email invalide" },
    },
    password: {
      required: "Mot de passe est requis",
      minLength: { value: 8, message: "Mot de passe doit contenir au moins 8 caractères" },
      pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: "Mot de passe doit contenir au moins une lettre et un chiffre" }
    },
    repeatPassword: {
      required: "Répéter le mot de passe est requis",
      validate: (value) => {
        return value === watch("password") || "Les mots de passe ne correspondent pas";
      }
    }
  }
  return (
    <Box>
      <Header />
      <Box 
        display={"flex"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        marginTop={"10vh"}
        height={"100vh"}
        marginLeft={"30%"}
        marginRight={"30%"}
        gap={2}
        className="login_register"
      >
          <Box 
            flex={1}
            marginTop={"2vh"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <h1 className="text-5xl">
              Bienvenue sur le portail dédié aux candidats !
            </h1>
            <p className="ms-2 font-semibold text-gray-500 dark:text-gray-400">
              Tu as sans doute toutes les meilleures raisons du monde pour t'inscrire à institut-ima, ne perds plus de temps.
            </p>
            <p className="ms-2 font-semibold text-gray-500 dark:text-gray-400">
              Tu as déjà un compte ? 
              <Link
                className="text-blue-500 dark:text-blue-400" 
                to="/login"> Connecte-toi</Link> ! 
            </p>
          </Box>
          <Form 
            className=" flex flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
            style={{flex: 1}}>
            <Box>
              {errors.fullname ? <p style={{color: "red"}}>{errors.fullname.message}</p> : null}
              <input
                placeholder="Votre Nom complet"
                className="form-input"
                type="text"
                {...register("fullname", requestForm.fullname)}
                style={errors.fullname ? styleErorr : {}}
              />
            </Box>
            <Box>
              {errors.email ? <p style={{color: "red"}}>{errors.email.message}</p> : null}
              <input
                placeholder="Votre Email"
                className="form-input"
                type="email"
                {...register("email", requestForm.email)}
                style={errors.email ? styleErorr : {}}
              />
            </Box>
            <Box>
              {errors.password && <p style={{color: "red"}}>{errors.password.message}</p>}
              <input
                placeholder="Mot de passe"
                type="password"
                className="form-input"
                {...register("password", requestForm.password)}
                style={errors.password ? styleErorr : {}}
              />
            </Box>
            <Box>
              {errors.repeatPassword && <p style={{color: "red"}}>{errors.repeatPassword.message}</p>}
              <input
                placeholder="Répéter le mot de passe"
                className="form-input"
                type="password"
                {...register("repeatPassword", requestForm.repeatPassword)}
                style={errors.repeatPassword ? styleErorr : {}}
              />
            </Box>
            <Box>
              <button
                type="submit"
                className="btn bg-secondary py-5 px-7 border-secondary text-theme-light leading-none w-full"
              >
                {isLoding ? "S'inscrire" : <CircularProgress />}
              </button>
            </Box>
          </Form>
      </Box>
    </Box>
  );
}