import { useEffect, useState } from "react";
import LayoutWrraper from "../../components/layout/LayoutWrraper";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Box, CircularProgress, Container } from "@mui/material";
import { getAllormation } from "../../services/formations.services";
import { backendApi } from "../../../api";
import { TableHeader } from "../../components/TableHeader";
import { TbaleBody } from "../manage/DisplayStudents";
import { fetchMe } from "../../services/users.services";
import "../../assets/admin.css";

export default function SingleFormationStudents() {
  const [selectedFormation, setSelectedFormation] = useState(null);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = useParams();
  const [auth, setAuth] = useState(null);

  const fetchStudents = async () => {
    const { formationID } = searchParams;
    try {
      const res = await backendApi.get(`formation/${formationID}/students`);
      setStudents(res.data);
      console.log("students -> ", res.data);
    } catch (err) {
      console.log("error -> ", err);
      toast.error("faild to get students");
    }
  };

  useEffect(() => {
    const { formationID } = searchParams;
    (async () => {
      setIsLoading(true);
      try {
        const response = await getAllormation();
        setSelectedFormation(
          response?.filter((form) => form?._id === formationID)?.[0]
        );
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("faild to get formation with that id");
        console.log(error);
      }
    })();

    fetchStudents();

    (async () => {
      try {
        setIsLoading(true);
        const res = await fetchMe();
        setAuth(res);
        setIsLoading(false);
      } catch (err) {
        toast.error("N'a pas obtenu vos données");
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <LayoutWrraper>
      <Container maxWidth="lg">
        {selectedFormation && (
          <>
            <h1 className="text-2xl">
              Liste des étudiants pour la formation 
              <span className=" text-gray-500"> (total {students?.length} étudiants)</span> : {selectedFormation?.name}
            </h1>
          </>
        )}
        {isLoading && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20%"}
            width={"100%"}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading  && (
          <div className="table_container mt-11">
            <table className="table_ p-[10px] border-b-slate-100 w-full border-collapse overflow-auto rounded-3xl">
              <TableHeader
                headers={[
                  "Etudiant",
                  "Cin",
                  "Formation",
                  "Status",
                  "Voir Profile",
                ]}
              />
              <tbody>
                {students.length > 0 ? (
                  <TbaleBody
                    students={students}
                    auth={auth}
                    setSelectedCenter={[]}
                    fetchStudents={fetchStudents}
                  />
                ) : (
                  <tr>
                    <td>Aucun étudiant trouvé, veuillez essayer</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </LayoutWrraper>
  );
}
