import img1 from "../../assets/images/a11.png"
import img2 from "../../assets/images/a12.png"

export default function AboutSection() {
  return (
    <section class="section">
      <div class="container">
        <div class="section-intro text-center lg:col-8 mx-auto">
          <h2 data-aos="fade-up-sm" class="title aos-init aos-animate">
            Notre Parcours et Notre Mission
          </h2>
          <p
            data-aos="fade-up-sm"
            data-aos-delay="100"
            class="subtitle aos-init aos-animate"
          >
            L’institut s’est vu confier, la responsabilité d’assurer des formations de qualité aux 
            jeunes étudiants de la ville d’Agadir et les préparer à la fois pour réussir
            leurs examens et à la fois pour assurer leurs avenirs et booster leurs carrières académiques et professionnelles.
          </p>
        </div>
        <div class="section-content lg:px-20">
          <div class="grid md:grid-cols-2 gap-6">
            <div
              data-aos="fade-up-sm"
              data-aos-delay="100"
              class="aos-init aos-animate"
            >
              <div class="border border-border shadow-inset p-10 rounded-4xl">
                <div class="mb-10">
                  <h2 class="mb-4 text-h4">Ensemble Nous Réussissons</h2>
                  <p>
                  Travail en groupe et collaboration entre étudiants, professeurs et associations, une approche intégrée."
                  </p>
                </div>

                <img
                  draggable="false"
                  src={img1}
                  loading="lazy"
                  width="520"
                  height="480"
                  class="w-full"
                  alt="Seamless Integration"
                />
              </div>
            </div>

            <div
              data-aos="fade-up-sm"
              data-aos-delay="150"
              class="aos-init aos-animate"
            >
              <div class="border border-border shadow-inset p-10 rounded-4xl">
                <div class="mb-10">
                  <h2 class="mb-4 text-h4">ETHIQUE</h2>
                  <p>
                    Citoyenneté, Tolérance, Respect des Droits et Obligations, Responsabilité Sociale et Diversité.
                  </p>
                </div>

                <img
                  draggable="false"
                  src={img2}
                  loading="lazy"
                  width="520"
                  height="480"
                  class="w-full"
                  alt="Scalable Infrastructure"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
