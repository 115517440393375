import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // Add interaction plugin
import "../assets/StudentEmploi.css"; // Import the CSS file
import { backendApi } from "../../api";
import toast from "react-hot-toast";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Modal, TextField } from "@mui/material";

const slotLabelFormat = {
  hour: "numeric",
  minute: "2-digit",
  omitZeroMinute: false,
  meridiem: "short",
  hour12: false,
};

const CustomEvent = ({ event, handleRemoveEvent}) => {
  if (!event) {
    return null;
  }
  return (
    <div className="bg-green-500 h-full border border-green-500 rounded-lg p-2 overflow-y-scroll">
        <button 
          onClick={() => handleRemoveEvent(event?.id)}
          className="absolute top-0 right-0 p-1 rounded-full bg-black text-white">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
        </button>
        <p className="text-sm font-semibold">
          {event?.title}
        </p>
        <div className=" "> 
            <p className="text-xs text-slate-800">Début à partir de {event?.start?.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</p>
            <p className="text-xs text-slate-800">jusqu'à {event?.end?.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</p>
        </div>
    </div>
  )
}

const StudentEmploi = ({student}) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [createName, setCreateName] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [weekLoop, setWeekLop] = useState(false);
  const [saveEvent, setSaveEvent] = useState(null);


  const getSlots = async () => {
    try {
      const res = await backendApi.get(`/slots/${student._id}/all`);
      if (res.status === 200) {
        const events = res.data.map((slot) => {
          return {
            id: slot._id,
            title: slot.title,
            start: new Date(slot.start),
            end: new Date(slot.end),
          };
        });
        setSelectedEvents(events);
      }
    } catch (err) {
      toast.error("Erreur lors de la récupération des événements");
    }
  }
  useEffect(() => {
    (async () => {
      await getSlots();
    })();
  }, []);

  const handleDateSelect = async (selectInfo) => {
    setIsPopupOpen(true);
    setSelectedEvents([...selectedEvents, selectInfo]);
    setSaveEvent(selectInfo);
  };

  const handleCreateSlot = async () => {
    try {
      const title  = createName;
      if (title) {
        const newEvent = {
          title,
          start: saveEvent.startStr,
          end: saveEvent.endStr,
        };
        const res = await backendApi.post("/slots/create", {
          user : student._id,
          slot : newEvent,
          loop : weekLoop
        })
        if (res.status == 201) {
          toast.success("Evénement créé avec succès");
          await getSlots();
          setCreateName("");
          setSaveEvent(null);
          setIsPopupOpen(false);
          setWeekLop(false)
          return;
        }
      }
    } catch(err) {
      console.log(err)
      toast.error("Erreur lors de la création de l'événement");
    }
    saveEvent.view.calendar.unselect();
  }
  
  const handleEventDrop = async (info) => {
    try {
      const updatedEvent = {
        id: info.event.id,
        start: info.event.startStr,
        end: info.event.endStr,
      };
      const res = await backendApi.post("/slots/update", {
        user: student._id,
        slot: updatedEvent
      });
      if (res.status === 201) {
        toast.success("Evénement mis à jour avec succès");
        const updatedEvents = selectedEvents.map((event) => {
          if (event.id === info.event.id) {
            return {
              ...event,
              start: info.event.startStr,
              end: info.event.endStr,
            };
          }
          return event;
        });
        setSelectedEvents(updatedEvents);
        return;
      }
      throw "";
    } catch (err) {
      toast.error("Erreur lors de la mise à jour de l'événement");
    }
  };

  const handleRemoveEvent = async (id) => {
    try {
      const deletSlots = [];
      deletSlots.push(selectedEvents.find((event) => event.id === id).id);
      const res = await backendApi.post("/slots/delete", {
        user : student._id,
        slots : deletSlots
      })
      if (res.status === 201) {
        toast.success("Evénement supprimé avec succès");
        const newEvents = selectedEvents.filter((event) => {
          if (event.id != id) {
            return event;
          }
        });
        setSelectedEvents(newEvents);
        return;
      }
      throw "";
    }catch (err) {
      toast.error("Erreur lors de la suppression de l'événement");
    }
  };

  return (
    <div className="bg-white p-5">
      <h1>Gérer l'emploi du temps de l'étudiant {student.fullname}</h1>
      <div className="calendar-container">
        <FullCalendar
          eventShortHeight={50}
          eventBackgroundColor="transparent"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Add interactionPlugin
          initialView="timeGridWeek"
          events={selectedEvents}
          height={"auto"}
          weekNumbers={true}
          weekNumberCalculation="local"
          editable={true} // Enable event editing (dragging and resizing)
          selectable={true} // Enable time slot selection
          selectMirror={true}
          slotMinTime="08:00:00"
          slotMaxTime="23:00:00"
          dropAccept={".droppable"}
          buttonIcons={{
            prev: "chevron-left",
            next: "chevron-right",
          }}
          eventDisplay="custom"
          eventContent={(eventInfo) => {
            if (eventInfo) {
              return (
                <CustomEvent 
                  event={eventInfo.event}  
                  handleRemoveEvent={handleRemoveEvent}
                />
              )
            }
          }}
          eventResize={handleEventDrop}
          droppable={true} // Make events droppable
          // selectAllow={}
          allDaySlot={false}
          slotLabelFormat={slotLabelFormat}
          locale="fr"
          buttonText={{
            today: "Aujourd'hui",
            month: "Mois",
            week: "Semaine",
            day: "Jour",
            list: "Liste",
          }}
          select={handleDateSelect} // Handle date selection
          eventDrop={handleEventDrop} // Handle event drop (dragging)
        />
      </div>
      {isPopupOpen && (
        <Modal
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <h2 className=" text-xl">Créer un Nouvel Événement</h2>
            <div className="mt-3">
              <label
                className=" font-light text-gray-500 mb-2"
              >
                Entrez une titre
              </label>
              <TextField 
                fullWidth
                onChange={(e) => setCreateName(e.target.value)}
                value={createName}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={weekLoop}
                  onChange={() => setWeekLop(!weekLoop)}
                  color="primary"
                />
              }
              label="Répéter le même jour chaque semaine"
            />
            <div className="flex justify-between mt-11">
              <Button
                onClick={() => {
                  setCreateName("");
                  if (saveEvent) {
                    const filteredEvents = selectedEvents.filter(e => e.start !== saveEvent.start && e.end !== saveEvent.end);
                    setSelectedEvents(filteredEvents);
                    saveEvent.view.calendar.unselect();
                    setWeekLop(false);
                    setSaveEvent(null);
                  }
                  setIsPopupOpen(false);
                }}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button 
                onClick={handleCreateSlot}
                variant="contained" 
                color="secondary">
                Enregistrer
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default StudentEmploi;
