import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router';
import toast, { Toaster } from 'react-hot-toast';
import 'aos/dist/aos.css';

if (!window.location.href.includes("admin")) {
  import("./assets/style/main.css")
  import("./assets/style/index.css")
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <>
    <Toaster />
    <RouterProvider router={router} />
  </>
);
