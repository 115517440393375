import studentIma from "../../assets/images/student_ima.jpg"
import herroBlol from "../../assets/images/hero_blob.webp"

export default function Contact () {
  return (
    <section class="section">
      <div class="container">
        <div class="border border-border bg-theme-light/60 rounded-3xl sm:rounded-4xl overflow-hidden relative">
          <div class="grid sm:grid-cols-12">
            
            <div class="px-5 sm:px-12 pr-12 py-7 sm:py-14 relative z-30 sm:col-span-7">
              <div className="mb-2 shadow-inset-sm border border-border px-4 pt-1.5 pb-1 rounded-3xl text-white text-sm font-light w-auto">
                <p className=" text-center">
                Si vous avez des questions, 
                <a className=" text-blue-400" target="_blank" href="https://web.whatsapp.com/send?phone=212661-586669&amp;text=hello"> Contactez-nous</a>
                </p>
              </div>
              <div class="section-intro pb-0">
                <h2 class="title">Prêt à Transformer Votre Avenir Académique ?</h2>
                <p class="subtitle mb-10">
                  Avez-vous une passion pour l'apprentissage et la construction d'un bon avenir?
                  Qu'attendez-vous, inscrivez-vous maintenant facilement
                </p>
                <a
                  class="btn btn-primary group flex items-center max-w-max gap-1"
                  href="/register"
                >
                  <span>Commencez maintenant</span>
                </a>
                
              </div>
            </div>

            <div class="relative h-[200px] sm:h-0 sm:col-span-5 z-20">
              <div class="absolute left-10 top-10 sm:left-0 sm:top-20 z-20 rounded-3xl border border-white/15 p-4">
                <img
                  src={studentIma}
                  loading="lazy"
                  class="sm:min-w-[700px] min-w-[500px] rounded-2xl overflow-hidden"
                  alt="Dashboard Image"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <img
            src={herroBlol}
            loading="lazy"
            alt=""
            draggable="false"
            data-aos="fade-in"
            data-aos-duration="1600"
            data-aos-easing="ease-out"
            class="absolute right-0 bottom-10 w-full max-w-[700px] z-10 opacity-80 aos-init aos-animate"
          />
        </div>
      </div>
    </section>
  );
}
