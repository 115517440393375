import Cookies from "js-cookie";
import { backendApi } from "../../api";

export const createPayment = async (data) => {
  try {
    const payResponse = await backendApi.post("/payment/create/", data)
    if (payResponse.status <= 202 && payResponse.status >= 200)
      return payResponse.data;
    else
      throw new Error(payResponse.data.message);
  } catch (error) {
    throw new Error(error.message);
  }
}

export const updatePayment = async (data) => {
  try {
    const payResponse = await backendApi.post("/payment/update/", data)
    if (payResponse.status <= 202 && payResponse.status >= 200)
      return payResponse.data;
    else
      throw new Error(payResponse.data.message);
  } catch (error) {
    throw new Error(error.message);
  }
}

export const paymentCenterStatisticsService = async (data) => {
  try {
    const { id } = data;
    const { period_start, period_end } = data;
    const payResponse = await backendApi.get(`/data/payments/center/${data.id}?period_start=${new Date(period_start).toISOString()}&period_end=${new Date(period_end).toISOString()}`)
    if (payResponse.status <= 202 && payResponse.status >= 200)
      return payResponse.data;
    else
      throw new Error(payResponse.data.message);
  } catch (error) {
    throw new Error(error.message);
  }
}


export const paymentFormationStatisticsService = async (data) => {
  try {
    const { id } = data;
    const { period_start, period_end } = data;
    const payResponse = await backendApi.get(`/data/payments/formations/${data.id}?period_start=${new Date(period_start).toISOString()}&period_end=${new Date(period_end).toISOString()}`)
    if (payResponse.status <= 202 && payResponse.status >= 200)
      return payResponse.data;
    else
      throw new Error(payResponse.data.message);
  } catch (error) {
    throw new Error(error.message);
  }
}

export const advanced_PaymentFormationStatisticsService = async (data) => {
  try {
    const { id } = data;
    const { period_start, period_end } = data;
    const payResponse = await backendApi.get(`/data/payments/filter/advance/`, {
      params : {
        period_start: new Date(period_start).toISOString(),
        period_end: new Date(period_end).toISOString(),
        formationId: data.formationId,
        centerId: data.centerId
      },
    })
    if (payResponse.status <= 202 && payResponse.status >= 200)
      return payResponse.data;
    else
      throw new Error(payResponse.data.message);
  } catch (error) {
    throw new Error(error.message);
  }
}

export const deletePaymentService = async (paymentID) => {
  try {
    const payResponse = await backendApi.post("/payment/delete/", {
      paymentID : paymentID
    });
    if (payResponse.status <= 202 && payResponse.status >= 200)
      return payResponse.data;
    return undefined;
  } catch (err) {
    return undefined;
  }
}