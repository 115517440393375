import LayoutWrraper from "../../components/layout/LayoutWrraper";
import { useState, useEffect, Fragment } from 'react';
import { getAllormation } from "../../services/formations.services";
import { 
  Box, 
  Button, 
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { TableHeader } from '../../components/TableHeader';
import Chip from '@mui/material/Chip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateFormationModal from "../../components/UpdateFormationModal";
import { backendApi } from "../../../api";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';


const TbaleBody = ({ formations, setFormations }) => {
  const [open, setOpen] = useState(false);
  const [forma, setForma] = useState();
  const [showDeletPopup, setShowDeletePopup] = useState(false)
  const [deleteFormation, setDeleteFormation] = useState();
  const [validationText, setValidationText] = useState();

  const navigate = useNavigate();

  const showDeleteAlert = (form) => {
    setDeleteFormation(form);
    setShowDeletePopup(true)
  }

  const handleOpen = (formation_data) => {
    setForma(formation_data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForma(null);
  };

  const handleDelete = async () => {
    if (validationText != ("DELETE_FORMATION_" + deleteFormation.name.toUpperCase()))  {
      toast.error("Le texte que vous avez entré ne correspond pas");
      return;
    }
    const data = {
      formationID : deleteFormation._id,
      confirmText : validationText,
      formationName : deleteFormation.name
    }
    try {
      const res = await backendApi.delete("/formations/delete/", {
        data : data
      })
      toast.success(res.data.message);
      const response_formations = await getAllormation();
      setFormations(response_formations)
      setShowDeletePopup(false)
    } catch(err) {
      toast.error(err.message);
    }
  }


  return (
    <>
      {showDeletPopup && (
        <Fragment>
          <Dialog
            open={showDeletPopup}
            onClose={() => setShowDeletePopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Alerte de Suppression d'un Formation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText  id="alert-dialog-description">
              Cher Administrateur,<br />
              Veuillez noter qu'un Formation  a été sélectionné pour suppression. 
              Veuillez entrer le texte ci-dessus pour confirmer
              <p
                style={{
                  fontWeight: "bold",
                  color : "blue", 
                  userSelect: "none",
                  marginTop: 20
                }}
              >
                {String("DELETE_FORMATION_" + deleteFormation?.name).toUpperCase()}
              </p>
              </DialogContentText>
              <TextField 
                  style={{ marginTop: 20 }}
                  fullWidth
                  id="Validation" 
                  variant="outlined" 
                  label="Entrer le texte"
                  onChange={(e) => setValidationText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDeletePopup(false)}
                variant="contained"
                autoFocus>Non</Button>
              <Button
                onClick={() => handleDelete()}
                color="error"
                variant="contained">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
      { forma && open && (
        <UpdateFormationModal
          setFormations={setFormations}
          handleModalClose={handleClose}
          modalOpen={open}
          formation={forma}
        />
      )}
      {formations.map((form, index) => (
        <tr key={index}>
          <td>{form?.name}</td>
          <td>
            <Chip
              color="success"
              icon={<PaidIcon />}
              label={`${form?.price} Dirham`}
            />
          </td>
          <td>{form?.duration} Mois</td>
          <td>
            <Box display={'flex'} gap={1}>              
              <Button 
                onClick={() => navigate(`/admin/formation/${form._id}/students`)} 
                color="success" variant="contained">
                <VisibilityIcon />
              </Button>
              <Button onClick={() => handleOpen(form)} color="primary" variant="contained">
                <ModeEditIcon />
              </Button>
              <Button 
                onClick={() => showDeleteAlert(form)}
                color="error" variant="contained">
                <DeleteIcon />
              </Button>
            </Box>
          </td>
        </tr>
      ))}
    </>
  );
};


export default function () {
  const [formations, setFormations] = useState([]);
  const [filteredFormations, setFilteredFormation] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchFromation = async () => {
      setIsLoading(true)
      try {
        const response = await getAllormation();
        setFormations(response);
        setFilteredFormation(response);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.log(error);
      }
    }
    fetchFromation();
  }, []);

  const onSearch = () => {
    const filterFormatin = formations.filter((formation) => (
      formation.name.toLowerCase().includes(search.toLowerCase()) ||
      formation.description.toLowerCase().includes(search.toLowerCase())
    ));
    setFilteredFormation(filterFormatin);
  }

  return (
    <LayoutWrraper>
      <Container maxWidth='lg'>
        <h1 className=" text-3xl">List des Formations</h1>
        {isLoading ?
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20%"}
            width={"100%"}> 
              <CircularProgress />
          </Box>
        : (
          <div className='table_container'>
            <input
              type='text'
              placeholder="Rechercher par nom, cin, email"
              className='search_table'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                onSearch()
              }}
            />
            <table className='table_'>
              <TableHeader headers={['Titre', 'Prix de la formation', 'Durée de la formation', 'Actions']} />
              <tbody >
                <TbaleBody setFormations={setFormations} formations={search.length > 0 ? filteredFormations : formations} />
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </LayoutWrraper>
  );
}