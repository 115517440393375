import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button, { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Link } from "react-router-dom";
import { isAuth, getUser, logout, IsAdmin } from "../services/auth.services";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import imaLogo from "../assets/images/ima.png";


const pages = ["Formation", "Centers", "Contact"];
const settings = ["Profile", "Logout"];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLogin, setIsLogin] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [user, setUser] = React.useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settingItemClickEvent = (setting) => async () => {
    if (setting === "Logout") logout();
    else if (setting === "Profile") navigate("/profile");
    handleCloseUserMenu();
  };

  React.useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      const isAuthRes = await isAuth();
      if (isAuthRes) {
        const isAdminRes = await IsAdmin();
        if (isAdminRes) setIsAdmin(true);
        else setIsAdmin(false);
        try {
          const userInfo = await getUser();
          setUser(userInfo);
        } catch (error) {
          toast.error(
            "Je n'ai pas pu obtenir les informations de l'utilisateur."
          );
          setIsLoading(false);
        }
        setIsLogin(true);
      } else setIsLogin(false);
      setIsLoading(false);
    };
    checkAuth();
  }, []);
  return (
    <header class="header z-30">
      <nav class="navbar container">
        <Link to={"/"} className="flex items-center gap-2">
          <img className="h-[35px] w-[35px] rounded-full" src={imaLogo} />
          <p className=" text-xl text-white font-bold">
            Institute Ima{" "}
            <span className=" font-light text-slate-500">(vesion 2.1)</span>
          </p>
        </Link>
        <div class="order-1 ml-auto flex items-center md:order-2 lg:ml-0">
        {isLoading ? (
              <CircularProgress />
            ) : isLogin ? (
              <a href={isAdmin ? "/admin" : "/profile"}>
                <Avatar>{user?.fullname?.charAt(0).toUpperCase()}</Avatar>
              </a>
            ) : (
              <div className="flex items-center gap-6">
                <Link to={"/login"}>Login</Link>
                <Link
                  class="btn btn-secondary hidden lg:inline-block"
                  to="/register"
                >
                  Register
                </Link>
              </div>
            )}
        </div>
      </nav>
    </header>
  );
}

export default Header;

const containerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "15px",
  paddingRight: "15px",
};
