import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import imaHeroBanner from "../../assets/images/ima_hero_section.jpg"
import herroBlol from "../../assets/images/hero_blob.webp"

export default function Banner() {
  return (
    <section className="relative lg:pb-[450px] overflow-hidden">
      <div className="container relative">
        <div className="row justify-center">
          <div className="xl:col-8 py-10 text-center">
            <div className="flex flex-col items-center gap-8">
              <div className="overflow-hidden">
                <div className="animate-headshot">
                  <div className="shadow-inset-sm border border-border px-4 pt-1.5 pb-1 rounded-3xl text-white text-sm font-light">
                    <p>
                        Un institut où vous pouvez apprendre, gagner et pratiquer n'importe quoi, n'importe où.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h1
                  data-aos="fade-up-sm"
                  className="font-normal text-h2 lg:text-[5rem] lg:leading-[1.2] mb-3 tracking-tighter aos-init aos-animate"
                >
                  L'avenir se charge, ne perdez pas votre temps.
                </h1>
                <div className="xl:col-9 mx-auto">
                  <p
                    data-aos="fade-up-sm"
                    data-aos-delay="100"
                    className="aos-init aos-animate"
                  >
                    Bienvenue à l’Institut de l'Éducation et de la Formation Professionnelle, de renommée, fondé en 2016 par des lauréats de l'université.
                  </p>
                </div>
              </div>
              <div
                className="flex flex-wrap gap-3 justify-center aos-init aos-animate"
                data-aos="fade-up-sm"
                data-aos-delay="150"
              >
                <a
                  className="btn btn-primary group flex items-center max-w-max gap-1"
                  href="/register"
                >
                  <span>Commencez maintenant.</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative container-fluid">
        <div
          className="mt-5 lg:mt-16 pb-10 aos-init aos-animate"
          data-aos="fade-up-sm"
          data-aos-delay="200"
        >
          <div className="p-6 border border-white/15 rounded-4xl mx-auto w-full sm:w-2/3 xl:max-w-[950px] object-cover lg:absolute lg:top-0 lg:left-1/2 lg:-translate-x-1/2">
            <img
              className="rounded-3xl overflow-hidden"
              src={imaHeroBanner}
              width="1024"
              height="784"
              loading="eager"
              alt="banner image"
            />
          </div>
        </div>
      </div>

      <div
        className="hidden lg:block absolute left-10 -bottom-20 w-full -z-10 aos-init aos-animate"
        data-aos="fade-in"
        data-aos-delay="500"
        data-aos-duration="2000"
        data-aos-easing="ease-out"
      >
        <img
          src={herroBlol}
          className="w-full max-w-[2000px] mx-auto lg:scale-110"
          loading="eager"
          alt=""
        />
      </div>
    </section>
  );
}
