import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import { isAuth } from "./services/auth.services";
import Login from "./Pages/Login";
import StudentProfile from "./Pages/Students/StudentProfile";
import Dashoard from "./admin/pages/Dashoard";
import { redirect } from "react-router-dom";
import DisplayStudents from "./admin/pages/manage/DisplayStudents";
import { AddCenter } from "./admin/pages/center/AddCenter";
import StudentNotApprovedList from "./admin/pages/manage/students/StudentNotApprovedList";
import Profile from "./admin/pages/manage/students/Profile";
import ErrorNotFound from "./admin/components/404";
import { CreateFormation } from "./admin/pages/formation/CreateFormation";
import DisplayFormation from "./admin/pages/formation/DisplayFormation";
import CentersStatistics from "./admin/pages/manage/statistics/payments.statistics";
import FormationsStatistics from "./admin/pages/manage/statistics/formations.statistics";
import AdvanceStatistics from "./admin/pages/manage/statistics/advance.statistics";
import NotPayedMonthAgo from "./admin/pages/manage/statistics/notPayedMonthAgo.statisctics";
import DisplayAllCenters from "./admin/pages/center/DisplayAllCenters";
import AdminSettings from "./admin/pages/AdminSettings";
import Admins from "./admin/pages/Admin";
import CreateStudent from "./admin/pages/manage/students/CreateStudent"
import SingleFormationStudents from "./admin/pages/formation/SingleFormationStudents";

import {AuthLayout, AdminPermissionLayout, SuperAdminPermissionLayout} from "./AuthWrraper";

export const router = createBrowserRouter([
  {
    path : "/",
    Component : Home,
  },
  {
    element: <AuthLayout />,
    children : [
      {
        path: "/profile",
        Component: StudentProfile,
      },
      {
        path : "admin",
        element : <AdminPermissionLayout />,
        children : [
          {
            path: "",
            Component: Dashoard,
          },
          {
            element : <SuperAdminPermissionLayout />,
            children : [
              {
                path: "instituts/ajouter",
                Component: AddCenter,
              },
              {
                path : "formations/create",
                Component : CreateFormation,
              },
              {
                path : "payments/statistics/centers/",
                Component : CentersStatistics,
              },
              {
                path : "payments/statistics/formations/",
                Component : FormationsStatistics,
              },
              {
                path : "payments/statistics/adnvaced/",
                Component : AdvanceStatistics,
              },
              {
                path : "payments/statistics/students_not_payed_last_month/",
                Component : NotPayedMonthAgo,
              },
              {
                path : "admins/",
                Component : Admins,
              },
            ]
          },
          {
            path: "students",
            Component: DisplayStudents,
          },
          {
            path: "students/not-approved/list",
            Component: StudentNotApprovedList,
          },
          {
            path : "students/profile/:userId",
            Component : Profile,
          },
          {
            path : "formations/all",
            Component : DisplayFormation,
          },
          {
            path : "formation/:formationID/students",
            Component : SingleFormationStudents,
          },
          {
            path : "404",
            Component : ErrorNotFound,
          },
          {
            path : "instituts/lists/",
            Component : DisplayAllCenters,
          },
          {
            path : "settings",
            Component : AdminSettings,
          },
          {
            path : "student/create",
            Component : CreateStudent
          },
        ]
      }
    ]
  },
  {
    path: "/register",
    Component: Register,
  },
  {
    path: "/login",
    Component: Login,
  },
]);