import { useState } from "react";

const questions = [
  {
    id: 1,
    q: "Quel est le prix des formations ?",
    a: "Les prix commencent à partir de 100 DH, selon le type de formation choisie.",
  },
  {
    id: 2,
    q: "Où sont situés les centres de formation ?",
    a: "Les centres sont présents dans plusieurs villes à travers le Maroc.",
  },
  {
    id: 3,
    q: "Quelle est la durée des formations ?",
    a: "La durée des formations varie en fonction du type de formation, commençant à partir d'un mois.",
  },
  {
    id: 4,
    q: "Comment m'inscrire à une formation ?",
    a: "L'inscription peut se faire en ligne ou directement dans l'un de nos centres.",
  },
  {
    id: 5,
    q: "Est-ce que les formations sont reconnues ?",
    a: "Oui, toutes nos formations sont reconnues par les autorités compétentes et les entreprises partenaires.",
  },
  {
    id: 6,
    q: "Quel type de formations proposez-vous ?",
    a: "Nous proposons des formations dans divers domaines professionnels, allant de la gestion à la technologie.",
  },
  {
    id: 7,
    q: "Y a-t-il des options de financement pour les formations ?",
    a: "Oui, nous offrons des options de financement pour rendre les formations accessibles à tous.",
  },
  {
    id: 8,
    q: "Quels sont les prérequis pour s'inscrire ?",
    a: "Les prérequis dépendent de chaque formation. Vous pouvez consulter les détails sur notre site ou dans nos centres.",
  },
];

export default function CommonQuestions() {
  const [openIndex, setOpenIndex] = useState(null); // state to track which accordion is open

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // toggle open/close
  };

  return (
    <section className="section">
      <div className="container">
        <div className="grid lg:grid-cols-12">
          <div className="lg:col-span-5">
            <div className="section-intro text-center lg:text-left">
              <h2 className="title aos-init aos-animate" data-aos="fade-up-sm">
                Questions Fréquemment Posées
              </h2>
              <p
                className="subtitle lg:w-3/5 mx-auto lg:mx-0 aos-init aos-animate"
                data-aos="fade-up-sm"
                data-aos-delay="100"
              >
                Vous avez une question ? Consultez nos FAQ pour des réponses rapides. 
                Si vous avez besoin de plus d'informations, n'hésitez pas à nous contacter. Nous sommes là pour vous aider !
              </p>
            </div>
            
          </div>
          <div className="lg:col-span-7">
            <div
              data-aos="fade-up-sm"
              data-aos-delay="150"
              className="aos-init aos-animate"
            >
              {questions.map((item) => (
                <div
                  key={item.id}
                  className={`accordion ${openIndex === item.id ? "active" : ""}`}
                >
                  <button
                    className="accordion-header text-left"
                    onClick={() => handleToggle(item.id)} // Toggle open state on click
                  >
                    <span>{item.q}</span>
                    <div className="accordion-icon w-[.8em] h-[.8em] relative">
                      <div
                        className={`w-[2px] h-full absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-white opacity-100 ${
                          openIndex === item.id ? "rotate-90" : ""
                        }`}
                      ></div>
                      <div
                        className={`w-[2px] h-full absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-white ${
                          openIndex === item.id ? "" : "rotate-90"
                        }`}
                      ></div>
                    </div>
                  </button>
                  <div
                    className={`accordion-content ${
                      openIndex === item.id ? "block" : "hidden"
                    }`}
                  >
                    <p>{item.a}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
