import LayoutWrraper from "../../components/layout/LayoutWrraper";
import { useState, useEffect, Fragment } from "react";
import { fetchMe, getAllUsers } from "../../services/users.services";
import { 
  Box, 
  Button, 
  CircularProgress, 
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableHeader } from "../../components/TableHeader";
import { useNavigate } from "react-router-dom";
import {  backendApi, imageApiUri } from "../../../api";
import toast from "react-hot-toast";
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllCenter } from "../../services/center.servies";

export const TbaleBody = ({ students, auth, fetchStudents, setSelectedCenter }) => {
  const navigate = useNavigate();
  const [showPoup, setShowPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [validationText, setValidationText] = useState();
  const [userId, setUserId] = useState();
  const navigateTo = (id) => {
    navigate(`/admin/students/profile/${id}`);
  };

  const showPoupHandler = (userID) => {
    setUserId(userID);
    setShowPopup(true);
  }
  const handleDelete = async () => {
    if (validationText !== String("DELETE" + document.location.pathname.replace(/\//g, "_")).toUpperCase()) {
      toast.error("Le texte de validation ne correspond pas");
      return;
    }
    try {
      setIsLoading(true);
      const res = await backendApi.delete("/user/delete", {
        data : {
          userID : userId
        }
      });
      if (res.status === 200) {
        await fetchStudents();
        setIsLoading(false);
        toast.success("Étudiant supprimé avec succès")
        setSelectedCenter("all")
        setShowPopup(false);
      }else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("N'a pas réussi à supprimer cet étudiant")
    }
  }
  return (
    <>
      {showPoup && (
        <Fragment>
          <Dialog
            open={showPoup}
            onClose={() => setShowPopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Alerte de Suppression d'un Formation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText  id="alert-dialog-description">
              Cher Administrateur,<br />
              Veuillez noter qu'un étudiante  a été sélectionné pour suppression. 
              Veuillez entrer le texte ci-dessus pour confirmer
              <br style={{
                marginBottom: 10,
                marginTop: 10
              }} />
              <span
                style={{
                  fontWeight: "bold",
                  color : "blue", 
                  userSelect: "none",
                  marginTop: 20
                }}
              >
                {String("DELETE" + document.location.pathname.replace(/\//g, "_")).toUpperCase()}
              </span>
              </DialogContentText>
              <TextField 
                style={{ marginTop: 20 }}
                fullWidth
                id="Validation" 
                variant="outlined" 
                label="Entrer le texte"
                onChange={(e) => setValidationText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowPopup(false)}
                variant="contained"
                autoFocus>Non</Button>
              <Button
                disabled={isLoading}
                onClick={() => handleDelete()}
                color="error"
                variant="contained">
                {isLoading ? <CircularProgress /> : "Supprimer"}
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
        )}
      {students.map((student, index) => (
        <tr className="p-[10px] border-b-slate-100 hover:bg-slate-100" key={index}>
          <td className="p-[10px] border-b-slate-100" >
            <div className="flex items-center h-[50px]">
              <img
                className="w-[50px] h-[50px] rounded-full object-cover mr-[20px]"
                onError={(e) => {
                  e.target.src = "https://media.istockphoto.com/id/871752462/vector/default-gray-placeholder-man.jpg?s=612x612&w=0&k=20&c=4aUt99MQYO4dyo-rPImH2kszYe1EcuROC6f2iMQmn8o="
                }}
                src={imageApiUri + student?.profile?.profileImage}
                alt={student?.fullname}
              />
              <div>
                <h2 className="text-[14px] font-normal text-gray-500">{student?.fullname}</h2>
                <p className="text-[14px] font-normal text-gray-500">
                  {student?.email} • {student?.profile?.phone}
                </p>
              </div>
            </div>
          </td>
          <td className="text-[14px] font-normal text-gray-500">{student?.profile?.cin}</td>
          <td>{student?.profile?.formation?.name}</td>
          <td>
            <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              {student?.profile?.isAlumni
                ? "Fini le formation"
                : "En cours de formation"}
            </span>
          </td>
          <td>
            <div className="action_buttons">
              <button
                onClick={() => navigateTo(student._id)}
                className="btn_show"
              >
                <span className="material-symbols-outlined">
                  <VisibilityIcon />
                </span>
              </button>
              {auth?.role === "super_admin" ? (
                <button
                  onClick={() => showPoupHandler(student._id)}
                  className="btn_show"
                  style={{
                    backgroundColor : "red"
                  }}
                >
                  <span className="material-symbols-outlined">
                    <DeleteIcon />
                  </span>
                </button>
              ) : ""}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default  function DisplayStudents () {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useState({});
  const [selectedCenter, setSelectedCenter] = useState("all");
  const [centers, setCenters] = useState([]);
  const fetchStudents = async () => {
    setIsLoading(true);
    const students = await getAllUsers();
    if (students) {
      setStudents(
        students.filter((student) => student?.profile?.isApproved === true)
      );
    }
    setIsLoading(false);
  };

  const filterStudentWithCenter  = async (id) => {
    setIsLoading(true)
    setSelectedCenter(id);
    const students = await getAllUsers();
    setStudents(students);
    if (id === "all") {
      setIsLoading(false)
      return;
    }
    const studentsCenter = students.filter(student => {
        if (student?.profile?.center?._id === id)
          return student;
    })
    setStudents(studentsCenter)
    setIsLoading(false)
  }
  useEffect(() => {
    fetchStudents();
    const fetchAuth = async () => {
      try {
        setIsLoading(true)
        const res = await fetchMe();
        setAuth(res);
        setIsLoading(false)
      } catch(err) {
        toast.error("N'a pas obtenu vos données")
        setIsLoading(false)
      }
    }
    fetchAuth();
  }, []);

  const onSearch = () => {
    const filteredStudents = students.filter((student) => {
      return (
        student.fullname.toLowerCase().includes(search.toLowerCase()) ||
        student.profile.cin.toLowerCase().includes(search.toLowerCase()) ||
        student.profile.phone.toLowerCase().includes(search.toLowerCase()) ||
        student.email.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredStudents(
      filteredStudents.filter(
        (student) => student?.profile?.isApproved === true
      )
    );
  };

  const fetchCenters = async () => {
    const centers = await getAllCenter();
    setCenters(centers);
  }
  useEffect(() => {
    fetchCenters();
  }, [])

  return (
    <LayoutWrraper>
      <Container maxWidth="lg">
        {isLoading ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20%"}
            width={"100%"}> 
              <CircularProgress /> 
          </Box>
        ) : (
          <div className="table_container">
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={5}
            >
              <input
                style={{
                  flex : "1"
                }}
                type="text"
                placeholder="Rechercher par nom, cin, email"
                className="search_table"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  onSearch();
                }}
              />
              <Select
              style={{ flex: "1" }}
              id="center"
              variant="outlined"
              onChange={(e) => filterStudentWithCenter(e.target.value)}
              value={selectedCenter === "all" ? "all" : selectedCenter || ""}
            >
              <MenuItem value={"all"}>Toute</MenuItem>
              {centers.map((item, index) => (
                <MenuItem key={index} value={item?._id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
            </Box>
            <table className="table_ p-[10px] border-b-slate-100 w-full border-collapse overflow-auto rounded-3xl">
              <TableHeader
                headers={[
                  "Etudiant",
                  "Cin",
                  "Formation",
                  "Status",
                  "Voir Profile",
                ]}
              />
              <tbody>
                {
                  search ? (
                    filteredStudents.length > 0 ? (
                      <TbaleBody 
                        students={filteredStudents} 
                        auth={auth} 
                        setSelectedCenter={setSelectedCenter}
                        fetchStudents={fetchStudents} 
                      />
                    ) : (
                      <tr><td>Aucun étudiant trouvé, veuillez essayer un autre nom</td></tr>
                    )
                  ) : (
                    students.length > 0 ? (
                      <TbaleBody 
                        students={students} 
                        auth={auth} 
                        setSelectedCenter={setSelectedCenter}
                        fetchStudents={fetchStudents} 
                      />
                    ) : (
                      <tr><td>Aucun étudiant trouvé, veuillez essayer</td></tr>
                    )
                  )
                }
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </LayoutWrraper>
  );
}

